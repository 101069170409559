import {
  getSyncLogByItineraryId,
  getSyncLogs,
  syncTravelData,
  getTravelManagementDetail,
  GetTravelManagement,
  getItineraryDetailByOrderId,
  getTravelManagementConfig,
  getOrderType,
  getOrderFieldList,
  upgradeOrderMicro,
  getOrderFields,
  getOrderMicroList,
  getOrderMicroDetail,
  getOrderTypes,
  setSyncTripManually,
  getSyncTripResult,
  getSyncTripValid,
} from './itinerary.action'
import { app } from '@ekuaibao/whispered'

export default [
  {
    id: '@itinerary-manage',
    reducer: () => import('./itinerary.reducer'),
    // 行程同步params:{id: 行程id, type: 平台类型}
    'sync:travel:data': (params) => {
      return syncTravelData(params)
    },
    // 根据多条行程id获取相关行程的同步日志
    'get:travel:manage:logs'(ids) {
      return getSyncLogs(ids)
    },
    // 根据单条行程的id获取同步日志
    'get:sync:logs:itinerary:id': (id) => {
      return getSyncLogByItineraryId(id)
    },
    'get:travel:management:detail': (params) => {
      return getTravelManagementDetail(params)
    },
    'get:travel:management': () => {
      return GetTravelManagement()
    },
    'get:Itinerary:detail:by:orderId': (params) => {
      return getItineraryDetailByOrderId(params)
    },
    'get:travelManagementConfig': (params) => {
      return app.dispatch(getTravelManagementConfig(params))
    },
    'get:order:type:list'() {
      return getOrderType()
    },
    'get:order:field:list'(params) {
      return getOrderFieldList(params)
    },
    'get:order:all:fields'() {
      return getOrderFieldList({
        type: ['flight', 'train', 'hotel', 'taxi', 'food', 'common', 'shop'].join(','),
      })
    },
    'get:upgradeOrderMicro'(params) {
      return upgradeOrderMicro(params)
    },
    'get:orderMicroDetail'(params) {
      return getOrderMicroDetail(params)
    },
    'get:orderFields'(params) {
      return getOrderFields(params)
    },
    'get:orderMicroList'(params) {
      return getOrderMicroList(params)
    },
    'get:orderTypes'(params) {
      return getOrderTypes(params)
    },
    'set:sync:trip'(flowId) {
      return app.dispatch(setSyncTripManually(flowId))
    },
    'get:sync:trip:result'(flowId) {
      return app.dispatch(getSyncTripResult(flowId))
    },
    'get:sync:trip:valid'(params) {
      return app.dispatch(getSyncTripValid(params))
    },
  },
  {
    path: '/trip-management-config',
    ref: '/',
    onload: () => import('./config/TripConfig'),
  },
  {
    path: '/trip-management-list',
    ref: '/',
    onload: () => import('./itinerary-list'),
  },
  {
    path: '/order-management-list',
    ref: '/',
    onload: () => import('./order-view'),
  },
  {
    path: '/order-management-config',
    ref: '/',
    onload: () => import('./config/OrderConfig'),
  },
  {
    point: '@@components',
    namespace: '@itinerary-manage',
    onload: () => [{ key: 'ItineraryManageConfig', component: () => import('./config/index') }],
  },
  {
    point: '@@layers',
    prefix: '@itinerary-manage',
    onload: () => import('./layer'),
  },
]
