import key from './key';
import { Resource } from '@ekuaibao/fetch';
var travelManagement = new Resource('/api/tpp/v2/travelManagement');
var permission = new Resource('/api/v2/datalink/platform');
var platformUpdate = new Resource('/api/v2/datalink/platform/updatePlatformAdminIds');
var datalink = new Resource('/api/v2/datalink');
var tppV2 = new Resource('/api/tpp/v2');
var originalOrderNo = new Resource('/api/tpp/v2/travelManagement/order/all');
var dataLinkV1 = new Resource('/api/v1/datalink');
var rule = new Resource('/api/v1/mapping/fieldMapping');
var newOrderMicro = new Resource('/api/tpp/v2/travelManagement/orderMicro');
export function getSyncTripCorp() {
    return {
        type: key.GET_SYNCTRIPCORP,
        payload: travelManagement.GET('/syncTrip/corp'),
    };
}
export function setSyncTripManually(flowId) {
    return {
        type: key.SET_SYNCTRIPMANUALLY,
        payload: travelManagement.POST('/syncTrip/manually', flowId),
    };
}
export function getSyncTripResult(flowId) {
    return {
        type: key.GET_SYNCTRIP_RESULT,
        payload: travelManagement.POST('/syncTrip/result', flowId),
    };
}
export function getSyncTripValid(param) {
    return {
        type: key.GET_SYNC_VALID,
        payload: travelManagement.POST('/syncTrip/valid', param),
    };
}
// 是否升级订单微服务
export function upgradeOrderMicro(params) {
    return travelManagement.GET('/getTravelManagementConfig', params);
}
// 新订单导出
export function exportOrderMicroList(params) {
    newOrderMicro.POST('/export/$orderType', params);
}
// 订单类型
export function getOrderTypes(params) {
    if (params === void 0) { params = {}; }
    return newOrderMicro.GET('/orderType', params);
}
// 订单微服务fileds
export function getOrderFields(params) {
    if (params === void 0) { params = {}; }
    return newOrderMicro.GET('/field/list/$orderType', params);
}
// 主订单列表
export function getOrderMicroList(param) {
    return newOrderMicro.POST('/getOrders/$orderType', param);
}
// 主订单详情
export function getOrderMicroDetail(param) {
    return newOrderMicro.GET('/getOrderDetail/$orderType/$orderId', param);
}
// 子订单列表
export function getOrderMicroChildList(param) {
    return newOrderMicro.POST('/getChildOrders/$orderType', param);
}
// 修改次数上限
export function modifyDataLinkCount(param) {
    return {
        type: key.MODIFY_DATALINK_COUNT,
        payload: dataLinkV1.PUT('/$id/count', param),
    };
}
// 订单详情退改签列表
export function getOriginalOrderNo(params) {
    return originalOrderNo.GET('/$dataLinkId', params);
}
export function getTravelManagementDetail(params) {
    return travelManagement.GET('/selectTravelDetail', params);
}
export function setTravelManagementConfig(params) {
    return {
        type: key.SET_TREVELMANAGEMENT_CONFIG,
        payload: travelManagement.PUT('/setTravelManagementConfig', params),
    };
}
/**
 * 获取行程管理配置信息
 * @param params {type: 'cityGroup' | 'travelUpgrade' | 'MANAGER_CONFIG' | 'applicationAmount' | 'tripBlackList' | 'travelCloseLoopConfig' | 'travelOneOrderConfirmConfig'}
 */
export function getTravelManagementConfig(params) {
    return {
        type: key.GET_TREVELMANAGEMENT_CONFIG,
        payload: travelManagement.GET('/getTravelManagementConfig', params),
    };
}
export function getTripDataLink(params) {
    return {
        type: key.GET_TRIP_DATALINK,
        payload: permission.GET('/type/$type', params),
    };
}
export function updateTripDataLink(data, done) {
    return {
        type: key.UPDATE_TRIP_DATALINK,
        payload: platformUpdate.PUT('/$id', data),
        done: done,
    };
}
export function getSyncResult() {
    return {
        type: key.GET_PASSENGER,
        payload: datalink.GET('/passenger/getSyncResult'),
    };
}
export function syncPassenger() {
    return {
        type: key.AYNC_PASSENGER,
        payload: datalink.PUT('/passenger/sync'),
    };
}
export function getBuyerSyncResult() {
    return {
        type: key.GET_BUYER,
        payload: datalink.GET('/buyer/result'),
    };
}
export function syncBuyer() {
    return {
        type: key.AYNC_BUYER,
        payload: datalink.PUT('/buyer/sync'),
    };
}
export function syncPlatform(params) {
    return {
        type: key.AYNC_PLATFORM,
        payload: travelManagement.PUT('/order/synTravelOrder', params),
    };
}
export function GetTravelManagement(params) {
    return {
        type: key.GET_CUSTOMTRAVELTYPE,
        payload: travelManagement.GET('/customTravelType', params),
    };
}
export function PostTravelManagement(params) {
    return {
        type: key.ADD_CUSTOMTRAVELTYPE,
        payload: travelManagement.POST('/customTravelType', params),
    };
}
export function UpdateTravelManagementActive(params) {
    return {
        type: key.UPDATE_CUSTOMTRAVELTYPEACTIVE,
        payload: travelManagement.PUT('/customTravelType/$id', params, params),
    };
}
/**
 * 获取行程列表
 * @param params： {}
 */
export function getItineraries(params) {
    return travelManagement.POST('/selectTravels', params);
}
/**
 * 根据行程id获取同步日志
 * @param ids: 行程id集合[]
 */
export function getSyncLogs(ids) {
    return tppV2.POST('/dataLink/logList', { ids: ids });
}
/**
 * 根据行程id获取同步日志
 * @param id: 行程id
 */
export function getSyncLogByItineraryId(id) {
    var newId = encodeURIComponent(id);
    return tppV2.GET("/dataLink/log/" + newId);
}
/**
 * 重新同步行程
 * @param value: {id: 行程id, type: 平台类型}
 */
export function syncTravelData(value) {
    return tppV2.POST('/dataLink/resync', value);
}
/**
 * 根据订单id获取关联行程详情
 * @param params {id: 订单id}
 */
export function getItineraryDetailByOrderId(params) {
    return travelManagement.GET('/getTravelDetail/$id', params);
}
export function getOrderFieldList(params) {
    return travelManagement.GET('/orderMicro/field/list/$type', params);
}
export function updateOrderFieldList(params) {
    var type = params.type, isLayout = params.isLayout, newFields = params.newFields;
    return travelManagement.POST('/orderMicro/field/update/$type', newFields, { type: type, isLayout: isLayout });
}
export function getOrderType() {
    return travelManagement.GET('/orderMicro/orderType');
}
export function setOrderConfig(params) {
    return travelManagement.POST('/orderMicro/config/update', params);
}
export function getRuleConfig(params) {
    return rule.GET('/getListByEntityId/$type', params);
}
export function setRuleConfig(params) {
    return rule.PUT('/$id', params);
}
export function getMainOrderFieldList(params) {
    return travelManagement.GET('/orderMicro/field/mainField/$type', params);
}
export function getOrderConfig() {
    return travelManagement.GET('/orderMicro/config/list');
}
export function validateFormulaGrammar(params) {
    return {
        type: key.VALIDATE_FORMULA_GRAMMAR,
        payload: datalink.POST('/entity/grammar', params),
    };
}
