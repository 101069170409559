export const ID = '@itinerary-manage'

export default {
  ID,
  SET_TREVELMANAGEMENT_CONFIG: `${ID}/SET_TREVELMANAGEMENT_CONFIG`,
  GET_TREVELMANAGEMENT_CONFIG: `${ID}/GET_TREVELMANAGEMENT_CONFIG`,
  GET_TRIP_DATALINK: `${ID}/GET_TRIP_DATALINK`,
  UPDATE_TRIP_DATALINK: `${ID}/UPDATE_TRIP_DATALINK`,
  GET_PASSENGER: `${ID}/GET_PASSENGER`,
  AYNC_PASSENGER: `${ID}/AYNC_PASSENGER`,
  GET_BUYER: `${ID}/GET_BUYER`,
  AYNC_BUYER: `${ID}/AYNC_BUYER`,
  AYNC_PLATFORM: `${ID}/AYNC_PLATFORM`,
  GET_CUSTOMTRAVELTYPE: `${ID}/GET_CUSTOMTRAVELTYPE`,
  ADD_CUSTOMTRAVELTYPE: `${ID}/ADD_CUSTOMTRAVELTYPE`,
  UPDATE_CUSTOMTRAVELTYPEACTIVE: `${ID}/UPDATE_CUSTOMTRAVELTYPEACTIVE`,
  MODIFY_DATALINK_COUNT: `${ID}/MODIFY_DATALINK_COUNT`,
  GET_ORDER_LIST: `${ID}/GET_ORDER_LIST`,
  GET_ORDER_DETAILS: `${ID}/GET_ORDER_DETAILS`,
  GET_ORDER_FIELDS: `${ID}/GET_ORDER_FIELDS`,
  VALIDATE_FORMULA_GRAMMAR: `${ID}/VALIDATE_FORMULA_GRAMMAR`,
  SET_SYNCTRIPMANUALLY: `${ID}/SET_SYNCTRIPMANUALLY`,
  GET_SYNCTRIP_RESULT: `${ID}/GET_SYNCTRIP_RESULT`,
  GET_SYNC_VALID: `${ID}/GET_SYNC_VALID`,
  GET_SYNCTRIPCORP: `${ID}/GET_SYNCTRIPCORP`,
}
